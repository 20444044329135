import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layouts/default'

const PageDocs = () => (
	<Layout>

		<div className="container-fluid ">
			<h1 className="text-white text-center mt-5 mb-5" style={{letterSpacing: '0.06em', fontWeight: 200 }}>Using Reldesk: Documentation</h1>
			<div className="container">

				<div className="row">
					<div className="col-md-10 offset-1 mb-4">
						<div className="card card-feature">
							<div className="help-body" style={{padding: 40}}>

								<p className="pt-2" style={{fontWeight: 100, fontSize: 18}}>
									Coming Soon
								</p>
							</div>
						</div>
					</div>
				</div>
		    </div>
		</div>


	</Layout>
)

export default PageDocs
